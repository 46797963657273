import { HttpClient } from "@angular/common/http"
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  signal,
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatDrawerMode } from "@angular/material/sidenav"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { MatSort, Sort } from "@angular/material/sort"
import { Router } from "@angular/router"
import { saveAs as importedSaveAs } from "file-saver"
import * as moment from "moment"
import { CookieService } from "ngx-cookie-service"
import { LocalStorageService } from "ngx-localstorage"
import {
  BehaviorSubject,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  map,
  of,
  switchMap,
} from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { WorkerserviceService } from "../../../../core/services/workerservice.service"
import { EditMyProfileComponent } from "../../pages/edit-my-profile/edit-my-profile.component"
import { MetadataComponent } from "../../pages/metadata/metadata.component"
import { TermsComponent } from "../../pages/terms/terms.component"
import { TryAgainComponent } from "../../pages/try-again/try-again.component"
import { ConfirmDeleteDialogComponent } from "../confirm-delete-dialog/confirm-delete-dialog.component"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"
import { HowToUploadComponent } from "../how-to-upload/how-to-upload.component"
import { ImportCsvComponent } from "../import-csv/import-csv.component"

var uniqid = require("uniqid")

@Component({
  selector: "app-csv-parser",
  templateUrl: "./csv-parser.component.html",
  styleUrls: ["./csv-parser.component.scss"],
})
export class CsvParserComponent implements OnDestroy, OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  displayedColumns: any[] = [
    "set_name",
    "created_date",
    "samples",
    "created",
    "jobresults",
  ]
  header = false
  selectedRow: any
  notifier = new Subject()
  pending = false
  lastPage = false
  scrollToTop = false
  samplesLimit = 10
  samplesSkip = 0
  totalSamples: any
  url = this.listSetsUrl(this.samplesSkip, this.samplesLimit)
  searchedWord: any
  initialLoadedSamples: any
  allSamplesSelected: any[] = []
  isSearched: boolean = false
  noQuery: boolean = false
  isSorted: boolean = false
  orderReverse: boolean = true
  nextPageLoading: boolean = false
  allSets: any
  private updateTableSubscription!: Subscription

  _sidenav: any
  element = new BehaviorSubject(<string>"")
  newSampleSet
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  viewContainerRef?: ViewContainerRef

  showFirstImport: boolean = false

  @ViewChild("fileImportInput") fileImportInput: any
  @ViewChild("sidenav") set sidenav(content: any) {
    if (content) {
      this._sidenav = content
    }
  }
  @ViewChild("viewport") viewport: any
  @ViewChild(MatPaginator) paginator!: MatPaginator

  tryAgainTimeOut: any
  selectedRowIndex = -1
  isLoading: boolean = false
  snackbarRef: MatSnackBarRef<any> | undefined
  highlight(row: any) {
    this.selectedRowIndex = row.id
    this.localStorageService.set("importedCSV", row.set_name)
  }

  firstPage = 0
  isPending = false
  pageSize = 50
  init = true
  count: any
  allSamples: any
  searchedValue: any
  currentBucket: any

  public searchQuery: string = ""
  currentPage = signal(this.firstPage)
  sortState = signal(["created_date", true])
  searchSubject = new Subject<string>()
  sortStateReverse: any
  sortStateValue: any

  private readonly loading = signal(true)
  private readonly query = signal("")

  showImage = false

  isClicked = false

  @ViewChild("confirmDialog") confirmDialog!: TemplateRef<any>

  constructor(
    public workerservice: WorkerserviceService,
    public commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClient,
    public snackbar: MatSnackBar,
    private cookieService: CookieService,
    public authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.newSampleSet = this.router.getCurrentNavigation()?.extras
    this.commonService.query = this.query
    // if (!this.cookieService.check(this.authService.username)) {
    //   this.cookieLogin();
    // }
    this.commonService.showFirstImport = false
    this.commonService.setCurrentStep(0)
    this.searchSubject
      .pipe(
        debounceTime(600),
        switchMap((query) => {
          this.searchedWord = query
          if (!query) {
            return of([])
          }

          return this.http.get(
            this.listSetsUrl(this.samplesSkip, this.samplesLimit, query),
          )
        }),
      )
      .subscribe((res: any) => {
        this.isSearched = false
        if (res.data) {
          this.allSets = res.data.slice(0, 10)
          this.totalSamples = res.total
        } else {
          this.resetAndSortUsers()
        }
      })
  }

  ngOnInit() {
    this.getSamples()
    this.updateTableSubscription =
      this.commonService.csvParserUpdated$.subscribe(() => {
        this.getSamples()
      })
    this.commonService.openSidenav.subscribe((res) => {
      if (res) {
        this.openSidenav(res)
        this.query.set(`${this.count}`)
        this.count++
      }
    })
    this.commonService.updateTable.subscribe((res) => {
      if (res) {
        this.count = uniqid()
        this.query.set(`${this.count}`)
      }
    })
    if (
      !(
        this.authService.permissions?.includes(
          "read:organization_sample_sets",
        ) || this.authService.permissions?.includes("read:platform_sample_sets")
      )
    ) {
      this.displayedColumns = [
        "set_name",
        "created_date",
        "samples",
        "jobresults",
      ]
    }

    if (this.allSets) {
      if (this.allSets.length > 0) {
        this.tryAgainTimeOut = setTimeout(() => {
          // this.router.navigateByUrl('/try-again')
          const dialogRef = this.dialog.open(TryAgainComponent, {
            width: "360px",
            height: "auto",
            hasBackdrop: true,
          })
          this.notifier.next(true)
          dialogRef.afterClosed().subscribe((response) => {
            if (response) {
              this.scrollToTop = true
              if (this.allSets.length > 0) {
                setTimeout(() => {
                  this.notifier.next(true)
                  const dialogRef = this.dialog.open(TryAgainComponent, {
                    width: "360px",
                    height: "auto",
                    hasBackdrop: true,
                  })
                }, 60 * 1000)
              }
            }
          })
        }, 60 * 1000)
      }
    }
  }

  listSetsUrl(
    skip: number = 0,
    limit: number = 10,
    keyword: string | null = null,
    order_field: string | null = null,
    order_reverse: string | null = null,
  ): string {
    let base = "/sample_sets"
    if (this.authService.permissions?.includes("read:platform_sample_sets")) {
      base += "/platform"
    } else if (
      this.authService.permissions?.includes("read:organization_sample_sets")
    ) {
      base += "/organization"
    }
    base += `?skip=${skip}&limit=${limit}`
    if (keyword) {
      base += `&keyword=${keyword}`
    }
    if (order_field) {
      base += `&order_field=${order_field}`
    }
    if (order_reverse) {
      base += `&order_reverse=${order_reverse}`
    }
    return base
  }

  public changeQuery(query: any): void {
    this.isSearched = true
    this.samplesSkip = 0
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.searchSubject.next(query)
    this.searchedValue = query
    if (!query || query.length === 0) {
      this.sortStateValue = null
      this.sortStateReverse = null
      this.isSearched = false
      this.noQuery = true
      this.totalSamples = this.initialLoadedSamples
      this.url = this.listSetsUrl(this.samplesSkip, this.samplesLimit)
      setTimeout(() => {
        this.nextPageLoading = false
        this.getSamples()
      }, 1000)
    } else {
      this.url = this.listSetsUrl(this.samplesSkip, this.samplesLimit, query)
    }
  }

  pageChanged(event: any) {
    this.nextPageLoading = true
    this.samplesSkip = event.pageIndex * event.pageSize
    this.samplesLimit = event.pageSize

    const baseUrl = this.listSetsUrl(this.samplesSkip, this.samplesLimit)
    let additionalParams = ""

    if (this.sortStateReverse && this.sortStateValue) {
      const order_reverse = (this.sortStateReverse === "desc").toString()
      additionalParams += `&order_field=${this.sortStateValue}&order_reverse=${order_reverse}`
    }

    if (this.searchedWord && this.searchedWord.length > 0) {
      additionalParams += `&keyword=${this.searchedWord}`
    }

    this.url = `${baseUrl}${additionalParams}`
    this.getSamples(this.url)
  }

  resetAndSortUsers() {
    this.allSets = [...this.allSamplesSelected]
  }

  getSamples(url?: any) {
    this.http
      .get(url ? url : this.listSetsUrl(this.samplesSkip, this.samplesLimit))
      .pipe(
        map((response: any) => {
          this.commonService.importedSamples = response.data
          this.allSets = response.data
          this.totalSamples = response.total
          this.initialLoadedSamples = response.total
          this.allSamples = response.data
          this.allSets = [...this.allSamples].slice(0, 10)
          if (response["data"]?.length < this.pageSize) {
            this.lastPage = true
          }
          if (response["data"].length < 1) {
            this.commonService.showFirstImport = true
          } else {
          }
          let orgs
          // if (orgs.length == 0) {
          //   console.log('no job data', this.jobs)
          //   this.router.navigateByUrl('/no-job');
          // }
          this.isLoading = true
          return orgs
        }),
      )
      .subscribe((res: any) => {
        this.nextPageLoading = false
        setTimeout(() => {
          this.pending = false
        }, 500)
      })
  }

  fileChangeListener($event: any): void {
    this.header =
      (this.header as unknown as string) === "true" || this.header === true
  }

  closeSidenav() {
    console.log("tatas")

    this._sidenav.close()
    this.commonService.sidenavClosed.next(true)
  }

  openSidenav(element: any) {
    this.currentBucket = this.localStorageService.set(
      "currentBucket",
      element.bucket,
    )
    if (element.status !== "CONFIGURED") {
      this.searchQuery = ""
      this.commonService.elementset_name = element.set_name
      this.commonService.elementId = element.id
      this.commonService.isTableClicked = true
      const dialogRef = this.dialog.open(MetadataComponent, {
        hasBackdrop: false,
        panelClass: "metadataDialog",
      })

      dialogRef.afterOpened().subscribe(() => {
        setTimeout(() => {
          if (
            dialogRef.componentInstance &&
            dialogRef.componentInstance.stepper
          ) {
            dialogRef.componentInstance.stepper.selectedIndex = 1
          }
        })
      })

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.router.navigate(["/metadata"])
        }
      })
    } else {
      if (!this._sidenav?.opened) {
        this.commonService.isSidenavOpen = true
      } else {
        this.commonService.isSidenavOpen = false
      }

      this._sidenav?.toggle()
      this.commonService.sidenavClosed.next(true)
      this.selectedRow = element
      let samplesetId = element.id
      if (this._sidenav?.opened) {
        this.element.next(element)
      }
    }
  }

  mode = new FormControl("over" as MatDrawerMode)

  importCsv() {
    const dialogRef = this.dialog.open(ImportCsvComponent, {
      // const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "640px",
      height: "326px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.success(res)
        this.router.navigate(["/metadata"])
      }
    })
  }

  howToUpload() {
    const dialogRef = this.dialog.open(HowToUploadComponent, {
      width: "761px",
      height: "629px",
      hasBackdrop: true,
    })
  }

  termsDialog() {
    const dialogRef = this.dialog.open(TermsComponent, {
      width: "450px",
      height: "256px",
      hasBackdrop: true,
    })
  }

  extract_job_from_s3_path(s3_path: any) {
    let s3_path_split = s3_path.split("/")
    let jobid = s3_path_split[s3_path_split.length - 2]
    return jobid
  }

  fetchJobResultUrl(job_id: string) {
    let base = `/jobs/${job_id}/results`
    if (this.authService.permissions?.includes("read:platform_jobs_result")) {
      base += "/platform"
    }
    return base
  }

  downloadFile(s3_path: any) {
    try {
      let job_id = this.extract_job_from_s3_path(s3_path)
      console.log(
        "Downloading results from:",
        job_id,
        " extracted from:",
        s3_path,
      )
      this.http
        .get(this.fetchJobResultUrl(job_id), {
          observe: "response",
          responseType: "blob",
        })
        .subscribe((res: any) => {
          let filename = res.headers
            .get("content-disposition")
            .split(";")[1]
            .split("=")[1]
            .replace(/\"/g, "")
          const blob = new Blob([res.body], { type: "text/csv" })
          importedSaveAs(blob, `${filename}`)
        })
    } catch (exc) {
      console.error("Download error:", exc)
    }
  }

  editProfile() {
    const dialogRef = this.dialog.open(EditMyProfileComponent, {
      // const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "450px",
      height: "550px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // this.success(res)
        this.router.navigate(["/metadata"])
      }
    })
  }

  announceSortChange(sortState: Sort) {
    this.sortStateReverse = sortState.direction
    this.sortStateValue = sortState.active

    this.isSorted = true
    this.orderReverse = !this.orderReverse
    let orderReverseValue = this.orderReverse ? "true" : "false"
    const validFields = ["created_date", "set_name"]
    if (validFields.includes(sortState.active)) {
      const baseQuery = this.listSetsUrl(
        this.samplesSkip,
        10,
        null,
        sortState.active,
        orderReverseValue,
      )

      let query = baseQuery
      if (this.searchedWord !== undefined) {
        query += `&keyword=${this.searchedWord}`
      }
      this.http.get(query).subscribe((res: any) => {
        this.allSets = res["data"]
        this.isSorted = false
      })
      this.url = query
      setTimeout(() => {
        this.isSorted = false
      }, 5000)
    }
  }

  getLocaleTimeOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("hh:mm A")
    return local
  }

  getLocaleDateOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY")
    return local
  }

  getLocaleDateOfAll(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY hh:mm A")
    return local
  }

  success(resp: any) {
    let url = `/sample_sets/configure`
    if (this.authService.permissions?.includes("update:platform_sample_set")) {
      url += "/platform"
    } else if (
      this.authService.permissions?.includes("update:organization_sample_set")
    ) {
      url += "/organization"
    }
    this.http
      .post(url, { set_id: resp[0].id })
      .pipe(
        catchError((err: any) => {
          throw "error in source. Details: " + err
        }),
      )
      .subscribe((response: any) => {
        if (response.set_name) {
          response.set_name = response.set_name
          delete response.set_name
        }
        this.allSets.unshift(response)

        this.commonService.showFirstImport = false
        let name = response.set_name
        let successfullyText = "was imported successfully"
        let sampleIdlink = response.samples[0].id
        let snackBarRef = this.snackbar.openFromComponent(
          CustomSnackbarComponent,
          {
            data: {
              name: name,
              successfullyText: successfullyText,
              linkSample: sampleIdlink,
            },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 10000,
          },
        )
        snackBarRef.afterDismissed().subscribe((res) => {
          if (res.dismissedByAction) {
            this.openSidenav(response)
          }
        })
      })
  }

  deleteSample(element: any, event: MouseEvent) {
    event.stopPropagation() // Stop the event from propagating to the row click event

    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      data: { sampleSetId: element.id },
      hasBackdrop: true, // Ensure the background is dimmed
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const sampleId = element.id
        let baseUrl = `/sample_sets/${sampleId}`
        if (
          this.authService.permissions?.includes("delete:platform_sample_set")
        ) {
          baseUrl += "/platform"
        } else if (
          this.authService.permissions?.includes(
            "delete:organization_sample_set",
          )
        ) {
          baseUrl += "/organization"
        }
        this.http.delete(baseUrl).subscribe(
          () => {
            // Refresh the list of sample sets
            this.getSamples()

            this.snackbar.open("Sample set deleted successfully", "Close", {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            })
          },
          (error) => {
            console.error("Error deleting sample set:", error)
            this.snackbar.open("Failed to delete sample set", "Close", {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            })
          },
        )
      }
    })
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.tryAgainTimeOut)
    this.notifier.next("")
    this.notifier.complete()
    this.updateTableSubscription.unsubscribe()
  }

  getLatestAggregateConcentrations(attributes: any[]): any {
    if (!attributes || attributes.length === 0) {
      return null
    }

    // Filter for AggregateConcentrations attributes and sort by date
    const aggregateConcs = attributes
      .filter((attr) => attr.name === "AggregateConcentrations")
      .sort(
        (a, b) =>
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime(),
      )

    // Return the most recent one or null if none found
    return aggregateConcs.length > 0 ? aggregateConcs[0] : null
  }
}
