import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import { BehaviorSubject, Subject } from "rxjs"

interface ColumnNames {
  [key: string]: string
}
@Component({
  selector: "app-try-again",
  templateUrl: "./try-again.component.html",
  styleUrls: ["./try-again.component.scss"],
})
export class TryAgainComponent implements OnInit {
  displayedColumns: any[] = [
    "set_name",
    "created_date",
    "samples",
    "concPath",
    "created",
  ]
  notifier = new Subject()
  columnNames: ColumnNames = {}
  csvRecords = new BehaviorSubject(<any>[])
  dataSource = new MatTableDataSource(this.displayedColumns)
  constructor(
    public http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  tryAgain() {
    this.dialog.closeAll()
  }
}
