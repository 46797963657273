<section class="container">
  <form [formGroup]="importCSVForm">
    <div class="container-row closing-btn">
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="create-set">
      <h2 class="title">Create New Sample Set</h2>
      <h4 class="sub-title">Enter a name for your new sample set.</h4>
      <mat-form-field class="path" appearance="outline">
        <mat-label>Sample Set Name</mat-label>
        <input
          matInput
          formControlName="set_name"
          placeholder="Sample Set Name"
          minlength="3"
          maxlength="900"
        />
      </mat-form-field>
      <mat-error
        *ngIf="importCSVForm.controls['set_name'].errors"
        class="error-msg"
      >
        Invalid Sample Set Name
      </mat-error>
      <div class="button-wrapper gap15 mt10px">
        <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
        <button
          mat-flat-button
          color="accent"
          class="text-white"
          (click)="navigateToDialog()"
          [disabled]="
            this.importCSVForm.value.set_name?.length == 0 ||
            this.importCSVForm.controls.set_name.invalid ||
            isClicked
          "
        >
          Create Sample Set
        </button>
      </div>
    </div>
  </form>
</section>
