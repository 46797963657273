import { HttpClient } from "@angular/common/http"
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  signal,
} from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { MatSort, Sort } from "@angular/material/sort"
import * as moment from "moment"
import { Subject, debounceTime, map, of, switchMap } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { AddNewUserDialogComponent } from "../../components/add-new-user-dialog/add-new-user-dialog.component"
import { EditRecoverUserDialogComponent } from "../../components/edit-recover-user-dialog/edit-recover-user-dialog.component"
import { EditUserDialogComponent } from "../../components/edit-user-dialog/edit-user-dialog.component"

@Component({
  selector: "app-user-account",
  templateUrl: "./user-account.component.html",
  styleUrls: ["./user-account.component.scss"],
})
export class UserAccountComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort
  @ViewChild(MatPaginator) paginator!: MatPaginator
  private readonly query = signal("")
  searchInput = ""

  sortState = signal(["email", true])

  private readonly loading = signal(true)

  isLoading = false
  organization: any
  searchedValue: any

  isTotalLoad = false
  isAllLoad = false
  isAllUserLoad = false
  allUsers: any[] = []
  searchSubject = new Subject<string>()

  displayedColumns: any[] = [
    "email",
    "role",
    "organization",
    "lastSession",
    "status",
    "edit",
  ]

  listUsers: any
  usersLimit = 10
  totalUser: any
  usersSkip = 0
  initialLoadedUsers: any
  totalCountUsers: any
  userEditInfo: any
  userId: any
  userData: any
  editHeight = "550px"
  addHeight = "570px"

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  role: any
  searchedWord: any

  url = this.constructUrl(this.usersSkip, this.usersLimit)

  users: any
  lastPage = false
  pageSize = 50
  totalSizePage: any
  isSearched: boolean = false
  isSorted: boolean = false
  sortStateReverse: any
  sortStateValue: any
  selectetOrgs: any

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public authService: AuthService,
    public snackbar: MatSnackBar,
    public commonService: CommonService,
  ) {
    this.userData = this.authService.userdata
    this.role = this.authService.role
    this.searchSubject
      .pipe(
        debounceTime(600),
        switchMap((query) => {
          this.isSearched = true
          this.searchedWord = query
          if (!query) {
            return of([])
          }
          return this.http.get(
            this.constructUrl(this.usersSkip, this.usersLimit, query),
          )
        }),
      )
      .subscribe((res: any) => {
        this.isSearched = false
        if (res.data) {
          this.listUsers = res.data.slice(0, 10)
          this.totalUser = res.total
        } else {
          this.totalUser = this.initialLoadedUsers
        }
      })
  }

  ngOnInit() {
    this.getUsers()
    this.authService.setAuthentication(true)
    this.getSingleUserInfo()
    if (this.authService.permissions?.includes("read:organization")) {
      this.http.get("/organizations/names").subscribe((res: any) => {
        this.commonService.orgs = res
      })
    } else {
      this.authService.waitForUser().then(() => {
        this.commonService.orgs = [this.authService.orgs?.[0]["name_id"]]
      })
    }
    if (this.authService.role == "1") {
      this.editHeight = "470px"
      this.addHeight = "470px"
    }
    this.commonService.userUpdated$.subscribe(() => {
      this.getUsers()
    })
  }
  ngAfterViewInit() {
    if (this.paginator) {
      this.listUsers.paginator = this.paginator
    }
  }

  announceSortChange(sortState: Sort) {
    this.sortStateReverse = sortState.direction
    this.sortStateValue = sortState.active
    this.isSorted = true

    const url = this.constructUrl(
      this.usersSkip,
      this.usersLimit,
      this.searchedWord,
      sortState.active,
      sortState.direction,
    )

    this.fetchAndAssignData(url)
    this.url = url
  }

  /**
   * Construct the URL based on the given conditions.
   *
   * @param skip Number of users to skip
   * @param limit Number of users to fetch
   * @param searchKeyword Optional keyword for searching
   * @param order_field Field to sort by
   * @param direction Sorting direction: asc or desc
   */
  private constructUrl(
    skip: number = 0,
    limit: number = 10,
    searchKeyword?: string,
    order_field?: string,
    direction?: string,
  ): string {
    let base = "/users"
    if (this.authService.permissions?.includes("read:platform_users")) {
      base += "/platform"
    } else if (
      this.authService.permissions?.includes("read:organization_users")
    ) {
      base += "/organization"
    }
    base += `?skip=${skip}&limit=${limit}`

    if (order_field !== undefined) {
      const order_reverse = (direction === "asc").toString()
      base += `&order_field=${order_field}&order_reverse=${order_reverse}`
    }
    if (searchKeyword !== undefined) {
      base += `&keyword=${searchKeyword}`
    }

    return base
  }
  /**
   * Fetch data from the provided URL and assign it to class properties.
   *
   * @param url URL to fetch data from
   */
  private fetchAndAssignData(url: string): void {
    this.http.get(url).subscribe((res: any) => {
      if (res.data) {
        this.isSorted = false
      }
      this.listUsers = res["data"]
    })
  }

  getUsers(url?: any) {
    if (this.searchedWord !== undefined) {
      this.http
        .get(
          url
            ? url
            : this.constructUrl(
                this.usersSkip,
                this.usersLimit,
                this.searchedWord,
              ),
        )
        .pipe(
          map((response: any) => {
            this.users = response.data.slice(0, 10)
            this.listUsers = [...this.users]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            this.totalSizePage = response.total
            if (response.data?.length) {
              this.isAllUserLoad = true
            }
            this.initialLoadedUsers = response.total
            this.totalUser = response.total
            this.totalCountUsers = response.total

            let orgs
            if (response.data?.length) {
              this.isAllLoad = true
              this.isSearched = false
            }

            this.isLoading = true
            return orgs
          }),
        )
        .subscribe((res: any) => {
          if (res) {
          }
        })
    } else {
      this.http
        .get(url ? url : this.constructUrl(this.usersSkip, this.usersLimit))
        .pipe(
          map((response: any) => {
            this.users = response.data.slice(0, 10)
            this.listUsers = [...this.users]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            if (response.data?.length) {
              this.isAllUserLoad = true
              this.isAllLoad = true
              this.isSearched = false
            }
            this.initialLoadedUsers = response.total
            this.totalUser = response.total
            this.totalCountUsers = response.total
            this.isLoading = true
            return null
          }),
        )
        .subscribe((res: any) => {
          if (res) {
          }
        })
    }
  }
  async getSingleUserInfo() {
    await this.authService.waitForUser()
    this.isTotalLoad = true
  }

  public changeQuery(query: any) {
    this.usersSkip = 0
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.searchSubject.next(query)
    this.searchedValue = query
    if (!query || query.length === 0) {
      this.sortStateValue = null
      this.sortStateReverse = null
      this.totalUser = this.initialLoadedUsers
      this.searchedWord = ""
      this.url = this.constructUrl(this.usersSkip, this.usersLimit)
      this.isSearched = true
      this.getUsers()
    } else {
      this.url = this.constructUrl(this.usersSkip, this.usersLimit, query)
      this.isSearched = true
    }
  }

  openAddNewUser() {
    const dialogRef = this.dialog.open(AddNewUserDialogComponent, {
      width: "450px",
      height: this.addHeight,
      hasBackdrop: true,
      panelClass: "add-new-user",
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }

  openEditUserDialog(
    email: string,
    role: string,
    id: string,
    givenName: string,
    middleName: string,
    familyName: string,
    bucket: string,
  ) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: "450px",
      height: this.editHeight,
      hasBackdrop: true,
      panelClass: "how-to-upload",
      data: {
        email: email,
        role: role,
        id: id,
        givenName: givenName,
        middleName: middleName,
        familyName: familyName,
        bucket: bucket,
      },
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }
  openReactivetedUserDialog(
    email: string,
    role: string,
    id: string,
    givenName: string,
    middleName: string,
    familyName: string,
    bucket: string,
  ) {
    const dialogRef = this.dialog.open(EditRecoverUserDialogComponent, {
      width: "450px",
      height: this.editHeight,
      hasBackdrop: true,
      panelClass: "how-to-upload",
      data: {
        email: email,
        role: role,
        id: id,
        givenName: givenName,
        middleName: middleName,
        familyName: familyName,
      },
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }

  pageChanged(event: any) {
    this.usersSkip = event.pageIndex * event.pageSize
    this.usersLimit = event.pageSize
    this.isSearched = true

    const baseUrl = this.constructUrl(this.usersSkip, this.usersLimit)
    let additionalParams = ""

    if (this.sortStateReverse && this.sortStateValue) {
      const order_reverse = (this.sortStateReverse === "desc").toString()
      additionalParams += `&order_field=email&order_reverse=${order_reverse}`
    }

    if (this.searchedWord && this.searchedWord.length > 0) {
      additionalParams += `&keyword=${this.searchedWord}`
    }

    this.url = `${baseUrl}${additionalParams}`
    this.getUsers(this.url)
  }

  getLocaleTimeOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("hh:mm A")
    return local
  }

  getLocaleDateOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY")
    return local
  }
}
