import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@auth0/auth0-angular"
// import { LoggedGuard } from "./core/guards/logged.guard"
// import { SampleGuard } from "./core/guards/sample.guard"
// import { UserGuard } from "./core/guards/user.guard"
// import { LoginComponent } from "./features/feature/pages/login/login.component"
import { CsvParserComponent } from "./features/feature/components/csv-parser/csv-parser.component"
import { Error404Component } from "./features/feature/components/error404/error404.component"
import { Error500Component } from "./features/feature/components/error500/error500.component"
import { ErrorsComponent } from "./features/feature/components/errors/errors.component"
import { NoJobResultComponent } from "./features/feature/components/no-job-result/no-job-result.component"
import { AboutComponent } from "./features/feature/pages/about/about.component"
import { CheckEmailComponent } from "./features/feature/pages/check-email/check-email.component"
import { FPassEmailComponent } from "./features/feature/pages/cognito-forgot/f-pass-email/f-pass-email.component"
import { FPassComponent } from "./features/feature/pages/cognito-forgot/f-pass/f-pass.component"
import { RegisterComponent } from "./features/feature/pages/forgot/register/register.component"
import { HomeComponent } from "./features/feature/pages/home/home/home.component"
import { MetadataComponent } from "./features/feature/pages/metadata/metadata.component"
import { MyProfileComponent } from "./features/feature/pages/my-profile/my-profile.component"
import { AccountsComponent } from "./features/feature/pages/organizations/accounts/accounts.component"
import { AllOrganizationComponent } from "./features/feature/pages/organizations/all-organization/all-organization.component"
import { CurrentOrganizationComponent } from "./features/feature/pages/organizations/current-organization/current-organization.component"
import { LogsComponent } from "./features/feature/pages/organizations/logs/logs.component"
import { ProgramsSetupComponent } from "./features/feature/pages/organizations/programs-setup/programs-setup.component"
import { SettingsComponent } from "./features/feature/pages/organizations/settings/settings.component"
import { PassChangedComponent } from "./features/feature/pages/pass-changed/pass-changed.component"
import { JobResultsRedesignComponent } from "./features/feature/pages/programs/job-results-redesign/job-results-redesign.component"
import { ProgramParametersComponent } from "./features/feature/pages/programs/program-parameters/program-parameters.component"
import { ProgramComponent } from "./features/feature/pages/programs/program/program.component"
import { ReleaseNotesComponent } from "./features/feature/pages/release-notes/release-notes.component"
import { TermsAndConditionsComponent } from "./features/feature/pages/terms-and-conditions/terms-and-conditions.component"
import { UserAccountComponent } from "./features/feature/pages/user-account/user-account.component"

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/metadata" },
  { path: "sample-set", component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: "view-programs",
    component: ProgramComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'job-results/:id', component: JobResults, canActivate: [AuthGuard] },
  {
    path: "job-results/:id",
    component: JobResultsRedesignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "job-results",
    component: JobResultsRedesignComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'job-results-re', component: JobResultsRedesignComponent, canActivate: [AuthGuard] },
  {
    path: "program-parameters",
    component: ProgramParametersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "program-parameters/:id",
    component: ProgramParametersComponent,
    canActivate: [AuthGuard],
  },
  { path: "metadata", component: CsvParserComponent, canActivate: [AuthGuard] },
  { path: "register", component: RegisterComponent },
  { path: "check-email", component: CheckEmailComponent },
  { path: "pass-changed", component: PassChangedComponent },
  { path: "about", component: AboutComponent, canActivate: [AuthGuard] },
  {
    path: "release-notes",
    component: ReleaseNotesComponent,
    canActivate: [AuthGuard],
  },
  { path: "forgot-pass", component: FPassComponent },
  { path: "forgot-pass/:email", component: FPassEmailComponent },
  { path: "errors", component: ErrorsComponent, canActivate: [AuthGuard] },
  { path: "not-found", component: Error404Component, canActivate: [AuthGuard] },
  { path: "geteway", component: Error500Component, canActivate: [AuthGuard] },
  { path: "no-job", component: NoJobResultComponent, canActivate: [AuthGuard] },
  {
    path: "import-metadata",
    component: MetadataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-account",
    component: UserAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-profile",
    component: MyProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "terms-conditions",
    component: TermsAndConditionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "all-organizations",
    component: AllOrganizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "current-org",
    component: CurrentOrganizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounts-org",
    component: AccountsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "program-setup",
    component: ProgramsSetupComponent,
    canActivate: [AuthGuard],
  },
  { path: "logs-org", component: LogsComponent, canActivate: [AuthGuard] },
  {
    path: "settings-org",
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", component: Error404Component },
  // { path: 'program', loadChildren: () => import('../app/features/feature/pages/programs/programs.module').then(mod => mod.ProgramsModule)}
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
