import { SelectionModel } from "@angular/cdk/collections"
import { HttpClient } from "@angular/common/http"
import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { LocalStorageService } from "ngx-localstorage"
import { CommonService } from "src/app/core/services/commonservice.service"

const ELEMENT_DATA: any = [
  { select: "fsdf", version: "Hydrogen", description: "H" },
]
@Component({
  selector: "app-targeted-absolute-dialog",
  templateUrl: "./targeted-absolute-dialog.component.html",
  styleUrls: ["./targeted-absolute-dialog.component.scss"],
})
export class TargetedAbsoluteDialogComponent implements OnInit {
  displayedColumns: string[] = ["select", "version", "description"]
  listTarget: any
  selection = new SelectionModel<any>(true, [])
  currentOrg: any
  allData: any
  initialSelectionState = new Map<any, boolean>()

  constructor(
    public http: HttpClient,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<TargetedAbsoluteDialogComponent>,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string

    this.listTarget = [data]
    this.allData = data
  }

  ngOnInit() {
    this.transformData()
  }

  transformData() {
    if (this.listTarget && this.listTarget.length > 0) {
      // Transforming data to the needed structure
      const versionArray = Object.keys(this.allData.allData).map((key) => {
        const isSelected = this.allData.allData[key][this.currentOrg] === true

        return {
          select: isSelected,
          version: key.split(":")[1],
          description: "Description for " + key.split(":")[1],
        }
      })

      // Updating the listTarget with transformed data
      this.listTarget = versionArray

      // Clearing any previous selection
      this.selection.clear()

      // Initialize the initial selection state
      this.initialSelectionState.clear()

      // Updating selection based on transformed data and setting initial state
      this.listTarget.forEach((row: any) => {
        if (row.select) {
          this.selection.select(row)
        }
        // Setting initial selection state for each row
        this.initialSelectionState.set(row, row.select)
      })

      // Sort the data by version
      this.sortByVersion()
    } else {
      console.error("Data is not initialized or in the expected format")
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.listTarget ? this.listTarget.length : 0 // Ensure listTarget exists
    return numSelected === numRows
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear()
      return
    }

    this.selection.select(...this.listTarget)
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`
  }

  createRequestBody() {
    return {
      items: this.listTarget
        .filter(
          (row: any) =>
            this.initialSelectionState.get(row) !==
            this.selection.isSelected(row),
        )
        .map((row: any) => {
          return {
            enabled: this.selection.isSelected(row),
            org_id: this.currentOrg,
            program_name: this.allData.name.split(":")[0],
            program_version: row.version,
          }
        }),
    }
  }

  onApplyClick() {
    const requestBody = this.createRequestBody()
    this.http.put("programs/viewers", requestBody).subscribe((res: any) => {
      if (res.status === 200) {
        this.commonService.updateVersion()
        this.dialogRef.close()
      }
    })
  }

  sortByVersion(): void {
    this.listTarget.sort((a: { version: string }, b: { version: string }) => {
      return this.compareVersions(a.version, b.version)
    })
  }

  compareVersions(versionA: string, versionB: string): number {
    const numA = versionA.replace("v", "").split(".").map(Number)
    const numB = versionB.replace("v", "").split(".").map(Number)

    for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
      if ((numA[i] || 0) < (numB[i] || 0)) return -1
      if ((numA[i] || 0) > (numB[i] || 0)) return 1
    }
    return 0
  }
}
