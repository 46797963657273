import { HttpClient } from "@angular/common/http"
import {
  AfterViewInit,
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { MatStepper } from "@angular/material/stepper"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import * as saveAs from "file-saver"
import { BehaviorSubject, Subscription, catchError } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { WorkerserviceService } from "src/app/core/services/workerservice.service"
import { environment } from "../../../../../environments/environment"
import { CustomSnackbarComponent } from "../../components/custom-snackbar/custom-snackbar.component"
import { HowToUploadComponent } from "../../components/how-to-upload/how-to-upload.component"

import { MatDialogRef } from "@angular/material/dialog"
import { LocalStorageService } from "ngx-localstorage"
import { SnackbarDropzoneComponent } from "../../components/snackbar-dropzone/snackbar-dropzone.component"
import { SnackbarPendingUploadComponent } from "../../components/snackbar-pending-upload/snackbar-pending-upload.component"
import { SnackbarUploadComponent } from "../../components/snackbar-upload/snackbar-upload.component"

export interface PeriodicElement {
  name: string
  position: number
  weight: number
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079 },
  { position: 2, name: "Helium", weight: 4.0026 },
  { position: 3, name: "Lithium", weight: 6.941 },
  { position: 4, name: "Beryllium", weight: 9.0122 },
  { position: 5, name: "Boron", weight: 10.811 },
]

@Component({
  selector: "app-metadata",
  templateUrl: "./metadata.component.html",
  styleUrls: ["./metadata.component.scss"],
})
export class MetadataComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  displayedColumnsT: string[] = ["position", "name", "weight", "delete"]
  dataSourceT = ELEMENT_DATA
  files: File[] = []
  isLinear = true
  active = false
  isButtonClicked = false
  allFiles: any = []
  statusClass: any = "not-active"
  isLoading = false
  dataSource = new MatTableDataSource<any>([])
  lastAddedFile: any
  id: string = ""
  set_name: any
  sampleIdlink: any
  email: any
  created_date: any
  samples: any[] = []
  columnNames: any = {}
  s3Path: any
  intervalId: any
  keysAndUrls: { [key: string]: string } = {}
  uploadProgress: number | null = null
  uploadProgressMap: { [sampleName: string]: number | null } = {}
  matchedFiles: { [sampleName: string]: any } = {}
  private xhrMap: { [sampleName: string]: XMLHttpRequest } = {}

  displayedColumns: any[] = []
  statusFromRespons: any
  element = new BehaviorSubject(<string>"")
  isDropzoneEnabled = true
  isAllSetsLoaded: boolean = false

  // @Input() element: any;
  @ViewChild("stepper", { static: false }) stepper!: MatStepper
  @ViewChild("sidenav") sidenav: any

  selectedRow: any

  finalData = []

  // columnNames: ColumnNames = {
  // }

  today = new Date()
  link: any
  sampleset_name: any
  page = 1
  pending = false
  pageSize = 50
  linkGuadsUser = environment.users_guide
  ownerId: any

  samplesetId: any
  sampleId: any
  set_nameSubscription?: Subscription
  pathForlder!: string
  statusSet: boolean = false

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  showFirstImport: boolean = false
  csvRecords = new BehaviorSubject(<any>[])

  sampleFileField!: string
  sampleNameField!: string
  setId!: "string"
  isClicked: boolean = false
  oldId: any
  res: any
  elements!: any[]
  isFailedUpload: boolean = false
  uploadError: string = ""
  set_nameField!: string
  uploadErrorMap: { [sampleName: string]: boolean } = {}
  unmatchedFiles: any[] = []
  cteateFolderPrevStep: any
  download: any
  uploadingSets: any[] = []
  uploadedSampleSet: any
  isUpload: boolean = true

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public workerService: WorkerserviceService,
    private router: Router,
    public commonService: CommonService,
    public authService: AuthService,
    public snackbar: MatSnackBar,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<MetadataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    setTimeout(() => {
      this.uploadedSampleSet = this.localStorageService.get(
        "importedCSV",
      ) as string
    }, 1000)
    // this.pathForlder = this.localStorageService.get("currentBucket") as string
    this.pathForlder = this.authService.pathForlder
    if (this.commonService.isTableClicked) {
      this.data = [
        {
          attributes: [
            {
              content: "Raw File Path",
              created_date: "2023-11-24T15:20:48.482068",
              name: "sample_file_field",
              owner_id: null,
            },
            {
              content: "Sample Name",
              created_date: "2023-11-24T15:20:48.482109",
              name: "sample_name_field",
              owner_id: null,
            },
            {
              content: "staging-test2",
              created_date: "2023-11-24T15:20:48.482138",
              name: "BUCKET_NAME",
              owner_id: null,
            },
          ],
          created_date: "2023-11-24T15:20:48.481555",
          email: "test2@test.com",
          id: this.commonService.elementId,
          owner_id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
          samples: [
            {
              attributes: [
                {
                  content: "sample01",
                  name: "Sample Name",
                },
                {
                  content: this.commonService.elementset_name,
                  name: "Sample Set",
                },
                {
                  content: `s3://${this.pathForlder}/${this.commonService.elementset_name}/RAW/sample01.raw`,
                  name: "Raw File Path",
                },
              ],
              id: "1ab2684b-65e8-42ac-a1d7-e35015809219",
              owner_id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
              sample_name: "sample01",
              status: "UNKNOWN",
              user: {
                email: "test2@test.com",
                groups: [
                  {
                    name: "staging",
                  },
                ],
                id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
                last_key_created: "2023-11-13T15:45:27.054117",
                name: "test2",
                role: 2,
              },
            },
          ],
          set_name: "",
          user: {
            email: "test2@test.com",
            groups: [
              {
                name: "staging",
              },
            ],
            id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
            last_key_created: "2023-11-13T15:45:27.054117",
            name: "test2",
            role: 2,
          },
        },
      ]
    }
  }

  ngOnInit() {
    if (this.data && this.data.responseData) {
      this.createAndLoadCSV(this.data.responseData)
    }

    setTimeout(() => {
      if (this.commonService.isTableClicked) {
        this.createAndLoadCSV(this.data)
      }
    }, 4000)

    if (this.commonService.isTableClicked) {
      this.isButtonClicked = true
      this.active = true
      setTimeout(() => {
        this.renderData(this.data)
      }, 300)
    }

    if (Array.isArray(this.data.responseData)) {
      this.isButtonClicked = true
      this.active = true
      setTimeout(() => {
        this.renderData(this.data)
      }, 300)
    }
    this.set_nameSubscription = this.commonService.set_nameNewFolder$.subscribe(
      (value) => {
        this.sampleset_name = value
      },
    )
  }

  addDynamicColumns() {
    setTimeout(() => {
      this.isUpload = false
      this.elements.forEach((attribute) => {
        if (
          attribute.name !== "Sample Name" &&
          attribute.name !== "Sample Set" &&
          attribute.name !== "Raw File Path"
        ) {
          if (!this.displayedColumnsT.includes(attribute.name)) {
            this.displayedColumnsT.push(attribute.name)
          }
        }
      })
    }, 600)
  }

  createAndLoadCSV(responseData: any) {
    if (
      !responseData ||
      responseData.length === 0 ||
      !responseData[0].samples ||
      responseData[0].samples.length === 0
    ) {
      return
    }

    const headers = responseData[0].samples[0].attributes.map(
      (attr: any) => attr.name,
    )

    let csvContent = headers.join(",") + "\r\n"
    responseData.forEach((dataItem: any) => {
      dataItem.samples.forEach((sample: any) => {
        const row = headers.map((header: any) => {
          const attribute = sample.attributes.find(
            (attr: any) => attr.name === header,
          )
          return attribute ? `"${attribute.content}"` : '""'
        })

        csvContent += row.join(",") + "\r\n"
      })
    })

    const blob = new Blob([csvContent], { type: "text/csv" })
    const csvFile = new File([blob], this.uploadedSampleSet, {
      type: "text/csv",
    })

    this.allFiles = [csvFile]
  }

  onSelect(event: any) {
    if (this.active) {
      this.allFiles.pop()
    }
    this.allFiles.push(event.addedFiles[0])
    this.active = true
    this.statusClass = "active"
  }

  onRemove() {
    this.allFiles = []
    this.active = false
    this.statusClass = ""
    this.commonService.isMetadataOpen = false
  }

  onSecondSelect(event: any) {
    let uploadedFileNames = event.addedFiles.map((file: any) => file.name)
    let shouldShowSnackbar = uploadedFileNames.some((fileName: any) =>
      this.unmatchedFiles.includes(fileName),
    )
    event.addedFiles.forEach((file: any) => {
      let sampleName
      let matchingKey
      if (file.name.includes(".raw")) {
        sampleName = file.name.replace(".raw", "")
        matchingKey = Object.keys(this.keysAndUrls).find(
          (key) => key + ".raw" === file.name,
        )
      } else {
        sampleName = file.name.replace(".d.zip", "")
        matchingKey = Object.keys(this.keysAndUrls).find(
          (key) => key + ".d.zip" === file.name,
        )
      }
      setTimeout(() => {
        if (!this.uploadingSets.includes(file.name)) {
          this.uploadingSets.push(file.name)
        }
      }, 1000)
      if (this.uploadingSets.includes(file.name)) {
        let snackBarRef = this.snackbar.openFromComponent(
          SnackbarPendingUploadComponent,
          {
            data: { names: this.unmatchedFiles.join(", ") },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 5000,
          },
        )
        snackBarRef.afterDismissed().subscribe((res) => {
          if (res.dismissedByAction) {
          }
        })
      }

      if (matchingKey) {
        if (!this.xhrMap[sampleName]) {
          const uploadUrl = this.keysAndUrls[matchingKey]
          this.uploadFile(file, uploadUrl, sampleName)
        }
      } else {
        if (!this.unmatchedFiles.includes(file.name)) {
          this.unmatchedFiles.push(file.name)
        }
      }
      if (!this.uploadProgressMap[sampleName]) {
        this.uploadProgressMap[sampleName] = 0
      }
    })
    if (this.unmatchedFiles.length > 0 && shouldShowSnackbar) {
      this.unmatchedFiles = []
      let snackBarRef = this.snackbar.openFromComponent(
        SnackbarDropzoneComponent,
        {
          data: { names: this.unmatchedFiles.join(", ") },
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: [
            "my-custom-snackbar-error",
            "mat-toolbar",
            "mat-primary",
          ],
        },
      )
      snackBarRef.afterDismissed().subscribe((res) => {
        if (res.dismissedByAction) {
        }
      })
    }
  }

  uploadFile(file: any, url: any, sampleName: string) {
    const xhr = new XMLHttpRequest()
    this.xhrMap[sampleName] = xhr

    xhr.open("PUT", url, true)

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        this.uploadProgressMap[sampleName] = Math.round(
          (event.loaded / event.total) * 100,
        )
      }
    }

    xhr.onload = () => {
      if (xhr.status === 200) {
        setTimeout(() => {
          // this.uploadProgressMap[sampleName] = null;
          // if (!this.unmatchedFiles.includes(file.name)) {
          //   this.unmatchedFiles.push(file.name);
          // }
        }, 15000)
        this.uploadErrorMap[sampleName] = false
      } else {
        this.uploadErrorMap[sampleName] = true
        let snackBarRef = this.snackbar.openFromComponent(
          SnackbarUploadComponent,
          {
            data: { name: file.name },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 5000,
          },
        )
        snackBarRef.afterDismissed().subscribe((res) => {
          if (res.dismissedByAction) {
          }
        })
      }
    }

    xhr.onerror = () => {
      this.uploadErrorMap[sampleName] = true
      let snackBarRef = this.snackbar.openFromComponent(
        SnackbarUploadComponent,
        {
          data: { name: file.name },
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: [
            "my-custom-snackbar-error",
            "mat-toolbar",
            "mat-primary",
          ],
          duration: 5000,
        },
      )
      snackBarRef.afterDismissed().subscribe((res) => {
        if (res.dismissedByAction) {
        }
      })
    }
    xhr.setRequestHeader("Content-Type", "")
    xhr.send(file)
  }

  startTimeout() {
    this.commonService.isMetadataOpen = false
    this.commonService.announceParserUpdate()
  }

  listSamplesUrl(samplesetId: string) {
    let base = `/samples/sample_set/${samplesetId}`

    if (this.authService.permissions?.includes("read:platform_sample_sets")) {
      base += "/platform"
    } else if (
      this.authService.permissions?.includes("read:organization_sample_sets")
    ) {
      base += "/organization"
    }

    return base
  }

  renderData(response: any) {
    if (Array.isArray(this.data.responseData)) {
      this.statusFromRespons = response.responseData[0].samples[0].status
      this.lastAddedFile = this.allFiles[this.allFiles.length - 1]
      this.sampleFileField = "Raw File Path"
      this.sampleNameField = "Sample"
      this.set_nameField = "Sample Set"
      this.id = response.responseData[0].id
      this.setId = response.responseData[0].id
      this.samplesetId = response.responseData[0].id
      this.sampleId = response.responseData[0].id
      this.oldId = response.responseData[0].id
      this.set_name = response.responseData[0].set_name
      this.sampleIdlink =
        response.responseData[0].samples[0].attributes[2].content
      this.email = response.responseData[0].user.email
      this.created_date = response.responseData[0].created_date
      this.samples.push(...response.responseData[0].samples)
      this.dataSource.data.map((row: any) => {
        if (this.columnNames[row.importCol]) {
          delete this.columnNames[row.importCol]
        } else {
          this.columnNames[row.importCol] = row.name
        }
      })
      this.retrieveUrls(this.samplesetId)
      this.http
        .get(this.listSamplesUrl(this.samplesetId))
        .subscribe((response: any) => {
          this.res = response
          this.dataSource.data = response
          this.elements = this.dataSource.data[0]["attributes"]
          this.addDynamicColumns()
          const allDone = response.every((item: any) => item.status === "DONE")
          this.statusSet = allDone
          if (allDone) {
            this.isDropzoneEnabled = false
          } else {
            this.isDropzoneEnabled = true
          }
          response[0].attributes.map((element: any) => {
            if (element.name === "Sample Set") {
              this.cteateFolderPrevStep = element.content
            }
          })
        })
      setTimeout(() => {
        this.stepper.next()
      }, 200)
      setTimeout(() => {
        if (response !== undefined) {
          const hasPendingSamples = this.res.some(
            (item: any) => item.status !== "DONE",
          )
          if (hasPendingSamples) {
            if (!this.intervalId) {
              this.intervalId = setInterval(() => {
                this.http
                  .get(this.listSamplesUrl(this.samplesetId))
                  .subscribe((response: any) => {
                    response[0].attributes.map((element: any) => {
                      if (element.name === "Sample Set") {
                        this.cteateFolderPrevStep = element.content
                      }
                    })
                    this.dataSource.data = response
                    const allDone = response.every(
                      (item: any) => item.status === "DONE",
                    )
                    this.statusSet = allDone
                    if (allDone) {
                      this.isDropzoneEnabled = false
                    } else {
                      this.isDropzoneEnabled = true
                    }
                    if (allDone && this.intervalId) {
                      clearInterval(this.intervalId)
                      this.intervalId = null
                    }
                  })
              }, 5000)
            }
          } else {
            if (this.intervalId) {
              clearInterval(this.intervalId)
              this.intervalId = null
            }
          }
        }
      }, 1500)
    } else if (this.commonService.isTableClicked) {
      this.isUpload = true
      this.statusFromRespons = response[0].samples[0].status
      this.lastAddedFile = this.allFiles[this.allFiles.length - 1]
      this.sampleFileField = "Raw File Path"
      this.sampleNameField = "Sample"
      this.set_nameField = "Sample Set"
      this.id = response[0].id
      this.setId = response[0].id
      this.samplesetId = response[0].id
      this.oldId = response[0].id
      this.set_name = response[0].set_name
      this.sampleIdlink = response[0].samples[0].attributes[2].content
      this.email = response[0].user.email
      this.created_date = response[0].created_date
      this.samples.push(...response[0].samples)
      this.dataSource.data.map((row: any) => {
        if (this.columnNames[row.importCol]) {
          delete this.columnNames[row.importCol]
        } else {
          this.columnNames[row.importCol] = row.name
        }
      })
      this.retrieveUrls(this.samplesetId)
      this.http
        .get(this.listSamplesUrl(this.samplesetId))
        .subscribe((response: any) => {
          if (response && response.length > 0) {
            this.data = response.map((item: any) => ({
              attributes: [
                {
                  content: "Raw File Path",
                  created_date: "2023-11-24T15:20:48.482068",
                  name: "sample_file_field",
                  owner_id: null,
                },
                {
                  content: "Sample Name",
                  created_date: "2023-11-24T15:20:48.482109",
                  name: "sample_name_field",
                  owner_id: null,
                },
                {
                  content: "staging-test2",
                  created_date: "2023-11-24T15:20:48.482138",
                  name: "BUCKET_NAME",
                  owner_id: null,
                },
              ],
              created_date: "2023-11-24T15:20:48.481555",
              email: "test2@test.com",
              id: item.id,
              owner_id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
              samples: [
                {
                  attributes: item.attributes,
                  id: "1ab2684b-65e8-42ac-a1d7-e35015809219",
                  owner_id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
                  sample_name: "sample01",
                  status: "UNKNOWN",
                  user: {
                    email: "test2@test.com",
                    groups: [
                      {
                        name: "staging",
                      },
                    ],
                    id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
                    last_key_created: "2023-11-13T15:45:27.054117",
                    name: "test2",
                    role: 2,
                  },
                },
              ],
              set_name: "",
              user: {
                email: "test2@test.com",
                groups: [
                  {
                    name: "staging",
                  },
                ],
                id: "d9f22c57-25b3-4640-90bf-ad7e7ab0d528",
                last_key_created: "2023-11-13T15:45:27.054117",
                name: "test2",
                role: 2,
              },
            }))
          }

          this.dataSource.data = response

          // check that samples are present
          if (this.dataSource.data.length > 0) {
            this.elements = this.dataSource.data[0]["attributes"]
            this.addDynamicColumns()
            const allDone = response.every(
              (item: any) => item.status === "DONE",
            )
            this.statusSet = allDone
            if (allDone) {
              this.isDropzoneEnabled = false
            } else {
              this.isDropzoneEnabled = true
            }
            response[0].attributes.map((element: any) => {
              if (element.name === "Sample Set") {
                this.cteateFolderPrevStep = element.content
              }
            })
          } else {
            // when the sample list is empty
            this.elements = []
            this.statusSet = false
            this.isDropzoneEnabled = true
          }
        })
      setTimeout(() => {
        this.stepper.next()
      }, 200)
      if (response !== undefined) {
        const hasPendingSamples = response.some(
          (item: any) => item.status !== "DONE",
        )
        if (hasPendingSamples) {
          if (!this.intervalId) {
            this.intervalId = setInterval(() => {
              this.http
                .get(this.listSamplesUrl(this.samplesetId))
                .subscribe((response: any) => {
                  if (
                    response &&
                    response.length > 0 &&
                    response[0].attributes
                  ) {
                    response[0].attributes.map((element: any) => {
                      if (element.name === "Sample Set") {
                        this.cteateFolderPrevStep = element.content
                      }
                    })
                  }
                  this.dataSource.data = response

                  // check that samples are present
                  if (this.dataSource.data.length > 0) {
                    this.elements = this.dataSource.data[0]["attributes"]
                    const allDone = response.every(
                      (item: any) => item.status === "DONE",
                    )
                    this.statusSet = allDone
                    if (allDone) {
                      this.isDropzoneEnabled = false
                    } else {
                      this.isDropzoneEnabled = true
                    }
                    if (allDone && this.intervalId) {
                      clearInterval(this.intervalId)
                      this.intervalId = null
                    }
                  } else {
                    // when the sample list is empty
                    this.elements = []
                    this.statusSet = false
                    this.isDropzoneEnabled = true
                  }
                })
            }, 5000)
          }
        } else {
          if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
          }
        }
      }
    } else {
      this.statusFromRespons = response[0].samples[0].status
      this.lastAddedFile = this.allFiles[this.allFiles.length - 1]
      this.sampleFileField = "Raw File Path"
      this.sampleNameField = "Sample"
      this.set_nameField = "Sample Set"
      this.id = response[0].id
      this.setId = response[0].id
      this.samplesetId = response[0].id
      this.oldId = response[0].id
      this.set_name = response[0].set_name
      this.sampleIdlink = response[0].samples[0].attributes[2].content
      this.email = response[0].user.email
      this.created_date = response[0].created_date
      this.samples.push(...response[0].samples)
      this.dataSource.data.map((row: any) => {
        if (this.columnNames[row.importCol]) {
          delete this.columnNames[row.importCol]
        } else {
          this.columnNames[row.importCol] = row.name
        }
      })
      this.retrieveUrls(this.samplesetId)
      this.http
        .get(this.listSamplesUrl(this.samplesetId))
        .subscribe((response: any) => {
          this.dataSource.data = response
          this.elements = this.dataSource.data[0]["attributes"]
          this.addDynamicColumns()

          const allDone = response.every((item: any) => item.status === "DONE")
          this.statusSet = allDone
          if (allDone) {
            this.isDropzoneEnabled = false
          } else {
            this.isDropzoneEnabled = true
          }
          response[0].attributes.map((element: any) => {
            if (element.name === "Sample Set") {
              this.cteateFolderPrevStep = element.content
            }
          })
        })
      setTimeout(() => {
        this.stepper.next()
      }, 200)
      // }
      if (response !== undefined) {
        const hasPendingSamples = response.some(
          (item: any) => item.status !== "DONE",
        )
        if (hasPendingSamples) {
          if (!this.intervalId) {
            this.intervalId = setInterval(() => {
              this.http
                .get(this.listSamplesUrl(this.samplesetId))
                .subscribe((response: any) => {
                  this.dataSource.data = response
                  const allDone = response.every(
                    (item: any) => item.status === "DONE",
                  )
                  this.statusSet = allDone
                  if (allDone) {
                    this.isDropzoneEnabled = false
                  } else {
                    this.isDropzoneEnabled = true
                  }
                  if (allDone && this.intervalId) {
                    clearInterval(this.intervalId)
                    this.intervalId = null
                  }
                })
            }, 5000)
          }
        } else {
          if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
          }
        }
      }
    }
  }

  retrieveUrls(sampleSetId: string) {
    this.http.get(`/sample_sets/${sampleSetId}/urls`).subscribe((res: any) => {
      this.keysAndUrls = res
      if (res) {
        this.isAllSetsLoaded = true
      }
    })
  }

  resetUploadError(element: any): void {
    let fileName = this.getRawFileName(element)
    this.uploadingSets = this.uploadingSets.filter((file) => file !== fileName)
    let sampleName = element.sample_name
    if (this.uploadErrorMap[sampleName]) {
      this.uploadErrorMap[sampleName] = false
    }
    this.uploadProgressMap[sampleName] = null
    this.matchedFiles[sampleName] = undefined
    delete this.xhrMap[sampleName]
  }

  getRawFileName(element: any): string {
    let rawFilePath = element.attributes.find(
      (attr: any) => attr.name === "Raw File Path",
    )?.content
    if (rawFilePath) {
      let splitPath = rawFilePath.split("/")
      return splitPath[splitPath.length - 1]
    }
    return ""
  }
  onClick(stepper: MatStepper) {
    this.commonService.isMetadataOpen = true
    this.isButtonClicked = true
    this.active = true
    let formData = new FormData()
    formData.append("file", this.allFiles[0])
    this.http
      .post(`/sample_sets/metadata_file`, formData)
      .subscribe((response: any) => {
        if (response) {
          this.isUpload = true
        }
        this.pathForlder = response.bucket
        this.renderData([response])
      })
  }

  getNestedAttributesDymamic(attributes: Array<any>, column: string): string {
    let content = attributes.find((el) => el.name === column)?.content
    return content || ""
  }

  openDialogInfo(): void {
    const dialogRef = this.dialog.open(HowToUploadComponent, {
      width: "761px",
      height: "629px",
      hasBackdrop: true,
      panelClass: "how-to-upload",
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let count =
          typeof this.commonService.query() === "string"
            ? 0
            : this.commonService.query() + 1
        this.commonService.query.set(count)
      }
    })
  }

  ngAfterViewInit() {
    this.stepper._getIndicatorType = () => "number"
  }

  onStepChange(event: any): void {
    this.uploadingSets = []
    this.commonService.setCurrentStep(event.selectedIndex)
    this.abortAllRequests()
    Object.keys(this.uploadProgressMap).forEach((key) => {
      this.uploadProgressMap[key] = null
    })
    setTimeout(() => {
      this.isUpload = false
    }, 1000)
  }

  private abortAllRequests() {
    Object.values(this.xhrMap).forEach((xhr) => xhr.abort())
    this.xhrMap = {}
  }

  transform(element: string) {
    // @ts-ignore
    const result = element
      .replace(/[0-9]/g, "")
      .replaceAll("_", " ")
      .replace(/([A-Z])/g, " $1")
      .trim()
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  getFilename(path: string): string {
    return path.split("/").pop() || ""
  }

  // decodes string error codes into better messages
  decodeErrorCode(error_code: string): string {
    if (error_code === "MZML_FAILURE") {
      return "mzML conversion failed"
    } else if (
      error_code === "QC_CHECK_FAILURE" ||
      error_code === "IAPE_FAILURE"
    ) {
      return "QC checks failed"
    } else {
      return "Unknown Error"
    }
  }

  success() {
    this.commonService.showFirstImport = false
    this.isClicked = true
    let body = {
      set_id: this.setId,
    }
    let url = `/sample_sets/configure`
    if (this.authService.permissions?.includes("update:platform_sample_set")) {
      url += "/platform"
    } else if (
      this.authService.permissions?.includes("update:organization_sample_set")
    ) {
      url += "/organization"
    }
    this.http
      .post(url, body)
      .pipe(
        catchError((err: any) => {
          throw "error in source. Details: " + err
        }),
      )
      .subscribe((response: any) => {
        this.commonService.announceParserUpdate()
        this.csvRecords.next([response, ...this.csvRecords.value])
        this.commonService.showFirstImport = false
        let name = response.set_name
        let successfullyText = "was imported successfully"
        let sampleIdlink = response.samples[0].id
        this.dialogRef.close()
        this.commonService.updateTable.next(response)
        let snackBarRef = this.snackbar.openFromComponent(
          CustomSnackbarComponent,
          {
            data: {
              name: name,
              successfullyText: successfullyText,
              linkSample: sampleIdlink,
            },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 5000,
          },
        )
        snackBarRef.afterDismissed().subscribe((res) => {
          if (res.dismissedByAction) {
            this.commonService.openSidenav.next(response)
          }
        })
      })
    this.commonService.isMetadataOpen = false
  }

  getNestedAttributes(attributes: Array<any>, column: string) {
    let content
    attributes.map((el) => {
      if (el.name === column) {
        content = el.content
      }
    })
    return content
  }

  getPrograms() {
    this.router.navigateByUrl(`programs`)
    this.commonService.sampleSetId.next(this.samplesetId)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init()
    this.dataSource.data = this.finalData.slice(0, this.page * this.pageSize)
  }

  private init() {
    if (this.dataSource) {
      return
    }
  }

  nextBatch(event: any) {
    const buffer = 10
    const end = this.dataSource.data.length
    if (end - event < buffer && this.pending === false) {
      if (this.dataSource.data.length < this.finalData.length) {
        this.pending = true
        this.page = Math.floor(end / this.pageSize)
        this.dataSource.data = [
          ...this.dataSource.data,
          ...this.finalData.slice(
            this.page * this.pageSize,
            (this.page + 1) * this.pageSize,
          ),
        ]
        setTimeout(() => {
          this.pending = false
        }, 300)
      }
    }
  }

  downloadTemplate() {
    let body = {
      set_name: this.sampleset_name,
    }
    this.http
      .post(`/sample_sets/template`, body, {
        responseType: "blob",
      })
      .subscribe((response: Blob) => {
        saveAs(response, this.sampleset_name + ".xlsx")
      })
  }

  downloadFile(file: any): void {
    const blob = new Blob([file], { type: file.type })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = file.name
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  }

  goToLink() {
    var redirectURL = this.commonService.getGitbookURL()
    window.open(redirectURL, "_blank")
  }

  clearRequests() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
    this.isLoading = false
    this.active = false
    this.data.responseData = ""
  }

  ngOnDestroy() {
    this.abortAllRequests()
    clearInterval(this.intervalId)
    this.dialog.closeAll()
    if (this.set_nameSubscription) {
      this.set_nameSubscription.unsubscribe()
    }
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
    this.commonService.isTableClicked = false
    this.isUpload = false
  }

  handleDelete(sample: any): void {
    let baseUrl = `/samples/${sample.id}`
    if (this.authService.permissions?.includes("delete:platform_sample")) {
      baseUrl += "/platform"
    } else if (
      this.authService.permissions?.includes("delete:organization_sample")
    ) {
      baseUrl += "/organization"
    }
    this.http.delete(baseUrl).subscribe(
      (response: any) => {
        // Update the data source to remove the deleted row
        this.dataSource.data = this.dataSource.data.filter(
          (s: any) => s.id !== sample.id,
        )

        console.log("Sample deleted successfully:", response)
      },
      (error: any) => {
        console.error("Error deleting sample:", error)
      },
    )
  }

  allQCChecksPassed(element: any): boolean {
    console.log("QC Checks for element:", element.qc_checks)
    if (
      !element.qc_checks ||
      element.qc_checks.length === 0 ||
      !Array.isArray(element.qc_checks)
    ) {
      return false
    }
    return element.qc_checks.every((check: any) => check.test_result)
  }

  numberOfQCChecksPassed(element: any): number {
    console.log("QC Checks for element:", element.qc_checks)
    if (
      !element.qc_checks ||
      element.qc_checks.length === 0 ||
      !Array.isArray(element.qc_checks)
    ) {
      return 0
    }
    return element.qc_checks.filter((check: any) => check.test_result).length
  }

  formatTestType(testType: string): string {
    const testTypeMap: { [key: string]: string } = {
      num_spectra_neg: "Spectral number check (negative mode)",
      num_spectra_pos: "Spectral number check (positive mode)",
      "num_candles_above_threshold_hilic-pos":
        "Intensity check (positive mode)",
      "num_candles_above_threshold_hilic-neg":
        "Intensity check (negative mode)",
      "num_candles_above_snr_threshold_hilic-pos": "SNR check (positive mode)",
      "num_candles_above_snr_threshold_hilic-neg": "SNR check (negative mode)",
      "num_candles_within_pnr_factor_hilic-pos": "PNR check (positive mode)",
      "num_candles_within_pnr_factor_hilic-neg": "PNR check (negative mode)",
      "num_candles_within_rt_shift_threshold_hilic-pos":
        "RT check (positive mode)",
      "num_candles_within_rt_shift_threshold_hilic-neg":
        "RT check (negative mode)",
    }

    return testTypeMap[testType] || testType // Returns original string if no mapping found
  }

  positionPopup(event: MouseEvent, popup: HTMLElement) {
    const rect = (event.target as HTMLElement).getBoundingClientRect()

    popup.style.left = `${rect.left + rect.width / 2}px`
    popup.style.top = `${rect.bottom + 10}px` // Changed to position below with 10px gap
  }
}
