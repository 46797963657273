import { PortalModule } from "@angular/cdk/portal"
import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { AppRoutingModule } from "src/app/app-routing.module"
import { CustomMatSelectSearchModule } from "src/app/custom-mat-search/custom-mat-select-search.module"
import { MaterialModule } from "src/app/material.module"
import { AccountsComponent } from "./accounts/accounts.component"
import { AllOrganizationComponent } from "./all-organization/all-organization.component"
import { CurrentOrganizationComponent } from "./current-organization/current-organization.component"
import { LogsComponent } from "./logs/logs.component"
import { ProgramsSetupComponent } from "./programs-setup/programs-setup.component"
import { SettingsComponent } from "./settings/settings.component"

@NgModule({
  declarations: [
    AllOrganizationComponent,
    CurrentOrganizationComponent,
    AccountsComponent,
    ProgramsSetupComponent,
    LogsComponent,
    SettingsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PortalModule,
    CustomMatSelectSearchModule,
  ],
  exports: [
    AllOrganizationComponent,
    CurrentOrganizationComponent,
    AccountsComponent,
    ProgramsSetupComponent,
    LogsComponent,
    SettingsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrganizationsModule {}
