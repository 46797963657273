import { HttpClient } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { LocalStorageService } from "ngx-localstorage"
import { Subscription } from "rxjs"
import { CommonService } from "src/app/core/services/commonservice.service"
import { TargetedAbsoluteDialogComponent } from "../../../components/targeted-absolute-dialog/targeted-absolute-dialog.component"

@Component({
  selector: "app-programs-setup",
  templateUrl: "./programs-setup.component.html",
  styleUrls: ["./programs-setup.component.scss"],
})
export class ProgramsSetupComponent implements OnInit, OnDestroy {
  programContainer: any[] = []
  listTarget: any

  uniqueVersions: any
  version: any
  uniqueVersionsSet: any
  namePr: any
  currentOrg: any
  activePrograms: number = 0
  sortedUniqueVersions: any
  allData: any
  isLoading: boolean = true
  versionUpdateSubscription!: Subscription

  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
    public commonService: CommonService,
    private localStorageService: LocalStorageService,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string
  }
  ngOnInit() {
    this.getProgramsInfo()
    this.versionUpdateSubscription =
      this.commonService.versionUpdate$.subscribe(() => {
        this.getProgramsInfo()
      })
  }
  getProgramsInfo() {
    this.http.get("/programs/viewers").subscribe((res: any) => {
      this.listTarget = res
      this.uniqueVersions = this.extractUniqueVersions(res)
      if (res) {
        this.allData = res
        this.activePrograms = 0
        this.countActiveVersions()
        this.isLoading = false
      }
    })
  }

  extractUniqueVersions(data: any) {
    this.uniqueVersionsSet = new Set<string>()

    Object.keys(data).forEach((key) => {
      this.namePr = key.split(":")[0]
      this.version = key.split(":")[1]

      if (typeof this.version === "string") {
        this.uniqueVersionsSet.add(this.version.trim())
      }
    })

    this.sortedUniqueVersions = Array.from(this.uniqueVersionsSet).sort(
      (a: any, b: any) => {
        const versionA = a
          .slice(1)
          .split(".")
          .map((num: string) => parseInt(num, 10))
        const versionB = b
          .slice(1)
          .split(".")
          .map((num: string) => parseInt(num, 10))
        for (let i = 0; i < versionA.length; i++) {
          if (versionA[i] !== versionB[i]) {
            return versionA[i] - versionB[i]
          }
        }
        return 0
      },
    )
    return this.sortedUniqueVersions
  }

  countActiveVersions() {
    if (this.allData) {
      Object.values(this.allData).map((el: any) => {
        Object.entries(el).map((type: any) => {
          if (type[0] === this.currentOrg && type[1] === true) {
            this.activePrograms += 1
          }
        })
      })
    }
  }

  openManageDialog() {
    const dialogRef = this.dialog.open(TargetedAbsoluteDialogComponent, {
      width: "640px",
      height: "697px",
      hasBackdrop: true,
      data: {
        name: this.namePr,
        version: this.uniqueVersionsSet,
        allData: this.listTarget,
      },
    })
    dialogRef.afterClosed().subscribe((res) => {})
  }

  checkOrgVersion(version: string): boolean {
    const programKey = `${this.namePr}:${version}`
    const programTargets = this.listTarget[programKey]
    return (
      programTargets &&
      this.currentOrg in programTargets &&
      programTargets[this.currentOrg] === true
    )
  }

  ngOnDestroy(): void {
    this.versionUpdateSubscription.unsubscribe()
  }
}
