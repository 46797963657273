import { HttpClient } from "@angular/common/http"
import { Component, Inject, OnDestroy } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { CustomSnackbarArhiveComponent } from "../custom-snackbar-arhive/custom-snackbar-arhive.component"

@Component({
  selector: "app-recover-user-dialog",
  templateUrl: "./recover-user-dialog.component.html",
  styleUrls: ["./recover-user-dialog.component.scss"],
})
export class RecoverUserDialogComponent implements OnDestroy {
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  disableButton: boolean = false
  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    public commonService: CommonService,
    private http: HttpClient,
    public snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {}

  recoverUser() {
    this.disableButton = true
    let body = {
      enabled: true,
      id: this.dataDialog.id,
    }

    this.http.put("/users/status", body).subscribe((res: any) => {
      if (res) {
        this.commonService.announceUserUpdate()
        this.dialog.closeAll()
        this.snackbar.openFromComponent(CustomSnackbarArhiveComponent, {
          data: {
            name: "User account was Re-activated",
            activeIcon: "icon",
            email: this.dataDialog.email,
          },
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: [
            "my-custom-snackbar-arhive",
            "mat-toolbar",
            "mat-primary",
          ],
          duration: 10000,
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.disableButton = false
  }
}
