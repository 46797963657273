import { HttpClient } from "@angular/common/http"
import { ChangeDetectorRef, Component, OnInit } from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { LocalStorageService } from "ngx-localstorage"
import { catchError } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { CustomSnackbarUserComponent } from "../custom-snackbar-user/custom-snackbar-user.component"

@Component({
  selector: "app-add-new-user-dialog",
  templateUrl: "./add-new-user-dialog.component.html",
  styleUrls: ["./add-new-user-dialog.component.scss"],
})
export class AddNewUserDialogComponent implements OnInit {
  addNewUserForm!: FormGroup
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  isReadonly = true
  organization: any
  selectCtrl = new FormControl()
  searchCtrl = new FormControl()
  options: any
  filteredOptions: any
  defaultOrganization: string | undefined
  currentOrg: string | undefined
  isLoading: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    private dialog: MatDialog,
    public http: HttpClient,
    public authService: AuthService,
    public commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {
    this.isLoading = false
    this.commonService.userLoading = false
    this.options = Array.isArray(this.commonService.orgs)
      ? this.commonService.orgs
      : []
    this.initializeOptions()
    this.organization = this.commonService.organization
  }

  ngOnInit() {
    this.commonService.clicked = false

    this.defaultOrganization = this.authService.orgs?.[0]["name_id"]
    this.currentOrg = this.localStorageService.get("clickedRowNameId") as string
    console.log(this.currentOrg)

    this.addNewUserForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      middleName: [""],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(320)],
      ],
      selectedGroup: [""],
      replacedGroup: [""],
    })

    this.addNewUserForm.get("org")?.valueChanges.subscribe((selectedValue) => {
      this.addNewUserForm.get("selectedGroup")?.setValue(selectedValue)
    })
    this.searchCtrl.valueChanges.subscribe(() => {
      this.filterOptions()
    })

    if (this.authService.permissions?.includes("create:platform_user")) {
      console.log("platform user")
      if (this.currentOrg) {
        this.selectCtrl.setValue(this.currentOrg)
      } else {
        this.selectCtrl.setValue(this.defaultOrganization)
      }
    } else if (
      this.authService.permissions?.includes("create:organization_user")
    ) {
      this.selectCtrl.disable()
      this.selectCtrl.setValue(this.defaultOrganization)
    }
  }

  constructUrl(): string {
    let base = "/users"
    if (this.authService.permissions?.includes("create:platform_user")) {
      base += "/platform"
    }
    return base
  }

  addNewUser() {
    this.commonService.userLoading = true
    const selectedOrganization = this.selectCtrl.value
    // Extracting necessary fields from the form values
    const { email, first_name, last_name, middleName } =
      this.addNewUserForm.value

    const organization = this.authService.permissions?.includes(
      "create:platform_user",
    )
      ? selectedOrganization
      : this.defaultOrganization
    const payload = {
      email,
      first_name: first_name,
      org: organization,
      last_name: last_name,
      middle_name: middleName || "",
    }
    this.http
      .post(this.constructUrl(), payload)
      .pipe(
        catchError((err: any) => {
          throw "error in source. Details: " + err
        }),
      )
      .subscribe((response: any) => {
        if (response) {
          this.commonService.announceUserUpdate()
        }
        this.dialog.closeAll()
        const message = `Account created. Invitation email sent to ${email}`
        this.snackbar.openFromComponent(CustomSnackbarUserComponent, {
          data: { text: message },
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: [
            "my-custom-snackbar-error",
            "mat-toolbar",
            "mat-primary",
          ],
        })
      })

    this.commonService.clicked = true
  }

  initializeOptions() {
    const defaultSelectedGroup = this.commonService.organization

    this.filterOptions()
    this.selectCtrl.setValue(defaultSelectedGroup)
  }

  addOption(value: string) {
    this.options.push(value)
    this.searchCtrl.setValue("")
    this.filteredOptions = [...this.options]

    // Re-initialize the form control
    this.selectCtrl = new FormControl(value)
  }

  clearSearch() {
    this.searchCtrl.setValue("")
  }

  filterOptions() {
    const filterValue = this.searchCtrl.value
      ? this.searchCtrl.value.toLowerCase()
      : ""
    if (Array.isArray(this.options)) {
      this.filteredOptions = this.options.filter((option: any) =>
        option.toLowerCase().includes(filterValue),
      )
    } else {
      this.filteredOptions = []
    }
  }
}
