<section class="container">
  <button class="close-btn" mat-icon-button [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <div *ngIf="!isLoading">
    <form [formGroup]="editMyProfile" (ngSubmit)="submitEditMyProfile()">
      <div class="content" *ngIf="this.authService.role != null">
        <h2 class="title">Edit Profile</h2>
        <h3 class="role">Role: {{ this.authService.role_name }}</h3>
        <div class="inputs">
          <mat-form-field class="first w48" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              formControlName="first_name"
              matInput
              placeholder="First Name"
              value="{{ this.authService.family_name }}"
            />
          </mat-form-field>
          <mat-form-field class="last w48" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              formControlName="last_name"
              placeholder="Last Name"
              value="{{ this.authService.given_name }}"
            />
          </mat-form-field>
        </div>
        <h3 class="username">Email</h3>
        <h3 class="user">{{ this.authService.username }}</h3>
      </div>
      <div class="button-wrapper gap15 mt10px">
        <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
        <button
          mat-flat-button
          color="accent"
          class="text-white"
          type="submit"
          [disabled]="!editMyProfile.valid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="isLoading">
    <div class="container-spinner">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</section>
