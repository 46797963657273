<div class="container">
  <mat-form-field appearance="outline" class="w100">
    <!-- <mat-label>Select</mat-label> -->
    <mat-select
      #matSelectElement
      (openedChange)="onOpenedChange($event)"
      (selectionChange)="onSelectionChange($event)"
      [(value)]="selectedOptions"
    >
      <mat-option>
        <ngx-mat-select-search
          [ngModel]="searchInput()"
          (ngModelChange)="changeQuery($event)"
          placeholderLabel="Search..."
          noEntriesFoundLabel="Not found"
        >
        </ngx-mat-select-search>
      </mat-option>
      <ng-container *ngIf="viewModel() as vm">
        <mat-option
          *ngFor="let option of vm.filteredOptions"
          [value]="option[selectOptions.value] || option"
        >
          <span *ngIf="roleToInt === 0">{{ option.set_name }}</span>
          <span *ngIf="roleToInt > 0"
            >{{ option.set_name }} {{ option.email }}</span
          ></mat-option
        >
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
<h4 class="empty-message" *ngIf="this.viewModel().filteredOptions.length == 0">
  No Sample sets imported
</h4>
