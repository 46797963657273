import { CommonModule } from "@angular/common"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { Router } from "@angular/router"
import { LocalStorageService } from "ngx-localstorage"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { MaterialModule } from "src/app/material.module"
import { MetadataComponent } from "../../pages/metadata/metadata.component"
import { CustomSnackbarMetadataComponent } from "../custom-snackbar-metadata/custom-snackbar-metadata.component"

@Component({
  selector: "app-import-csv",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
  ],
  templateUrl: "./import-csv.component.html",
  styleUrls: ["./import-csv.component.scss"],
})
export class ImportCsvComponent implements OnInit {
  fileNameRegex = /^[a-zA-Z0-9-_\.]*$/
  importCSVForm = new FormGroup({
    set_name: new FormControl("", Validators.pattern(this.fileNameRegex)),
  })
  pathForlder!: string
  isClicked: boolean = false

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    public commonService: CommonService,
    public authService: AuthService,
    public snackbar: MatSnackBar,
    private localStorageService: LocalStorageService,
  ) {
    this.importCSVForm.controls.set_name.setValue(
      this.commonService.set_nameNewImport.value,
    )
    this.isClicked = false
  }
  ngOnInit() {
    this.pathForlder = this.authService.pathForlder
  }

  navigateToDialog(data?: any) {
    this.isClicked = true
    this.createStructure((data?: any) => {
      const dialogRef = this.dialog.open(MetadataComponent, {
        hasBackdrop: false,
        panelClass: "metadataDialog",
        data: { responseData: data },
      })

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // Perform any necessary actions upon successful dialog closure
          this.router.navigate(["/metadata"])
        }
      })
    }, false) // Pass false to avoid opening the dialog again
  }

  createStructure(
    callback: (data?: any) => void,
    openDialog: boolean = true,
  ): void {
    setTimeout(() => {
      this.isClicked = false
    }, 3000)
    this.commonService.set_nameFolder(
      this.importCSVForm.controls.set_name.value,
    )
    let body = {
      set_name: this.importCSVForm.controls.set_name.value,
    }

    this.http
      .post("/sample_sets/s3_structure", body, { responseType: "text" })
      .subscribe((res: any) => {
        this.localStorageService.set(
          "importedCSV",
          this.importCSVForm.controls.set_name.value,
        )
        const re = new RegExp("(([[^}]+)?{s*[^}{]{3,}?:.*}([^{]+])?)")
        if (re.test(res)) {
          let data
          try {
            data = JSON.parse(res)
          } catch (e) {
            console.log("not a json")
          }
          if (Array.isArray(data)) {
            callback(data)
          }
        }
        let data = JSON.parse(res)
        if (
          data.set_name &&
          data.set_name === this.importCSVForm.controls.set_name.value
        ) {
          callback() // Call the callback without data
        } else {
          // Attempt to parse the response as JSON
          try {
            let data = JSON.parse(res)
            if (Array.isArray(data)) {
              // Only open the dialog if the flag is true
              if (openDialog) {
                this.navigateToDialog(data)
              }
              callback(data)
            } else {
              // Handle unexpected JSON structure
              console.error("Unexpected JSON structure:", data)
              this.handleErrorResponse("Unexpected JSON structure")
            }
          } catch (e) {
            console.error("Error parsing response as JSON:", e)
            this.handleErrorResponse(e)
          }
        }
      })
  }

  private handleErrorResponse(error: any) {
    this.snackbar.openFromComponent(CustomSnackbarMetadataComponent, {
      data: { name: "An error occurred" },
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ["my-custom-snackbar-error", "mat-toolbar", "mat-primary"],
      duration: 10000,
    })
    this.dialog.closeAll()
  }
}
