<section class="content-err-all" [ngSwitch]="data.status">
  <div class="closing-btn">
    <button mat-icon-button (click)="onTryAgainClick()" (click)="enableRun()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <article class="content-errors mb60px" *ngSwitchCase="'Wrong auth'">
    <div>
      <img src="../../../../../assets/icons/file-error.svg" />
    </div>
    <div class="title">
      <!-- File Error -->
    </div>
    <div class="text text-center mb60px err-width" [innerHTML]="data.message">
      <!-- {{data.message.statusText}}<br> -->
      {{ data.message }}
    </div>
    <div>
      <button
        mat-flat-button
        color="warn"
        class="ptpb h50 log-btn"
        mat-dialog-close
      >
        Ok
      </button>
    </div>
  </article>
  <article class="content-errors mb60px" *ngSwitchCase="'400'">
    <div>
      <img src="../../../../../assets/icons/file-error.svg" />
    </div>
    <div class="title">
      <!-- File Error -->
    </div>
    <div class="text text-center mb60px err-width" [innerHTML]="data.message">
      <!-- {{data.message.statusText}}<br> -->
      {{ data.message }}
    </div>
    <div>
      <button
        mat-flat-button
        color="warn"
        class="ptpb h50 log-btn"
        mat-dialog-close
      >
        Ok
      </button>
    </div>
  </article>
  <article class="content-errors mb60px" *ngSwitchCase="'404'">
    <div>
      <img src="../../../../../assets/icons/file-error.svg" />
    </div>
    <div class="title">
      <!-- File Error -->
    </div>
    <div class="text text-center mb60px err-width" [innerHTML]="data.message">
      <!-- {{data.message.statusText}}<br> -->
      {{ data.message }}
    </div>
    <div>
      <button
        mat-flat-button
        color="warn"
        class="ptpb h50 log-btn"
        mat-dialog-close
      >
        Ok
      </button>
    </div>
  </article>
  <article class="content-errors mb60px" *ngSwitchCase="'500'">
    <div>
      <img src="../../../../../assets/icons/file-error.svg" />
    </div>
    <div class="title">
      <!-- File Error -->
    </div>
    <div class="text text-center mb60px err-width" [innerHTML]="data.message">
      <!-- {{data.message.statusText}}<br> -->
      {{ data.message }}
    </div>
    <div>
      <button
        mat-flat-button
        color="warn"
        class="ptpb h50 log-btn"
        mat-dialog-close
        (click)="onTryAgainClick()"
      >
        Try again
      </button>
    </div>
  </article>
  <article class="content-errors mb60px" *ngSwitchDefault>
    <div class="title err-width">
      {{ data.message }}
    </div>
    <button
      mat-flat-button
      color="warn"
      class="ptpb h50 log-btn"
      mat-dialog-close
    >
      OK
    </button>
  </article>
</section>
