<div
  [ngClass]="{
    'test-h': this.commonService.isSidenavOpen,
    '': !this.commonService.isSidenavOpen,
  }"
>
  <div class="header" *ngIf="!this.commonService.isSidenavOpen">
    <div class="container-row p20 pb0">
      <div class="w50">
        <!-- <h2>{{this.sampleset_name?.content}}</h2> -->
        <ng-container *ngFor="let attribute of sampleNameTitle">
          <ng-container *ngIf="attribute.name === 'Sample Set'">
            <h2>{{ attribute.content }}</h2>
          </ng-container>
        </ng-container>
      </div>
      <div class="w50 align-end gap15 buttons">
        <button mat-stroked-button color="accent">Export</button>
        <button
          mat-flat-button
          color="accent"
          class="text-white apply"
          (click)="getPrograms()"
        >
          Apply Program
        </button>
      </div>
    </div>
    <div class="container-row gap15 padding-title">
      <div *ngFor="let el of link">
        <a routerLink="/{{ el.fileName }}.csv" class="file-path"
          >Source Metadata</a
        >
      </div>
      <div>
        <span>Uploaded on </span
        ><span class="bold">{{ this.created_date | date: "M/d/y" }}</span>
      </div>
      <div>by {{ this.email }}</div>
    </div>

    <!-- <mat-form-field class="input-search">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search input text" #input>
    </mat-form-field> -->
  </div>
</div>
<div class="content-sidenav">
  <article>
    <div *ngIf="!this.commonService.isSidenavOpen" class="csv-result-table">
      <cdk-virtual-scroll-viewport
        itemSize="25"
        class="example-viewport"
        (scrolledIndexChange)="nextBatch($event)"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          class="csv-table mat-elevation-z8"
        >
          <ng-container
            [matColumnDef]="column"
            *ngFor="let column of displayedColumns"
          >
            <th
              class="main"
              mat-header-cell
              *matHeaderCellDef
              [ngClass]="{
                hidden:
                  column.indexOf('Sample Set') > -1 || !columnNames[column],
              }"
            >
              {{
                columnNames[column] === "Sample"
                  ? columnNames[column].replace("Sample", "Sample Name")
                  : columnNames[column] || column
              }}
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="tableHead main-table"
              [ngClass]="{
                hidden:
                  column.indexOf('Sample Set') > -1 || !columnNames[column],
              }"
            >
              {{ getNestedAttributes(element.attributes, column) }}
              <!-- </div> -->
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
    <div *ngIf="this.commonService.isSidenavOpen">
      <div class="container-spinner">
        <div class="spinner">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </article>
</div>
